// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoginPage_login-form__rQkut {\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  display: flex;\n  /* flex-direction: column; */\n}\n", "",{"version":3,"sources":["webpack://./src/pages/admin/auth/login/LoginPage.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,aAAa;EACb,4BAA4B;AAC9B","sourcesContent":[".login-form {\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  display: flex;\n  /* flex-direction: column; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"login-form": "LoginPage_login-form__rQkut"
};
export default ___CSS_LOADER_EXPORT___;
